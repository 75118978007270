/** @jsxImportSource solid-js */
import dayjs from "dayjs"
import { createResource, createEffect, createSignal } from "solid-js"

const ReadingTime = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="20"
    height="20"
  >
    <circle
      cx="12"
      cy="12"
      r="10"
      class="dark:fill-gray-900 fill-gray-100 stroke-gray-600 dark:stroke-gray-400"
    ></circle>
    <path
      class="dark:fill-gray-400 fill-gray-600"
      d="M13 11.59l3.2 3.2a1 1 0 0 1-1.4 1.42l-3.5-3.5A1 1 0 0 1 11 12V7a1 1 0 0 1 2 0v4.59z"
    ></path>
  </svg>
)

async function fetchFeaturedBlogPosts(origin: string) {
  const response = await fetch(`${origin}/api/blog/featuredPosts`, {
    method: "GET",
  })
  return await response.json()
}
export default function FeaturedPosts({ origin }: { origin: string }) {
  const [response] = createResource(() => fetchFeaturedBlogPosts(origin))
  const [posts, setPosts] = createSignal([])
  createEffect(() => {
    if (response()?.featuredPosts?.length) {
      setPosts(response().featuredPosts)
    }
  })

  // Function to add the class when the image is loaded
  function applyClassOnImageLoad(event: Event) {
    const img = event.currentTarget as HTMLImageElement
    img.classList.remove("opacity-0")
    img.classList.add("opacity-1")
  }

  return (
    <div class="min-h-12 flex flex-col lg:flex-row items-center lg:items-start lg:justify-evenly lg:h-[800px] mt-16 lg:mt-32">
      {posts().map((post: any) => (
        <>
          <a
            class="cursor-pointer flex flex-col max-w-[425px]"
            href={`/posts/${post.slug}`}
          >
            <img
              class="rounded md:min-h-[230px] lg:h-[290px] opacity-0 transition-opacity motion-reduce:transition-none duration-200"
              loading="lazy"
              width={425}
              height={290}
              src={
                (post.data.imageUri as string) ??
                "https://site-images-prod.s3.amazonaws.com/home-page-image.png"
              }
              alt={`thumbnail image for ${post.data.title}`}
              onload={applyClassOnImageLoad} // Call the function when the image is loaded
            />
            <h4 class="font-bold text-gray-850 dark:text-gray-200 text-xl pt-6">
              {post.data.title}
            </h4>
            <div class="text-sm text-gray-500 dark:text-gray-400 pt-1">
              <span class="pr-1">{post.data.author}</span>
              &#8226;
              <span class="pl-1">
                {dayjs(post.data.published).format("MMM D, YYYY")}
              </span>
            </div>
            {post.data.readingTime && (
              <div class="flex pt-1 pb-0 text-sm text-gray-500 dark:text-gray-400">
                <span class="pr-2 -ml-0.5">
                  <ReadingTime />{" "}
                </span>
                {post.data.readingTime}{" "}
                {post.data.readingTime === 1 ? "min" : "mins"}
              </div>
            )}
            <div class="font-serif text-gray-800 dark:text-gray-300 pt-4">
              {post.data?.introContent?.slice(0, 125)}...
            </div>
            <span class="dark:text-indigo-400 text-indigo-800 font-bold text-base pt-3 underline underline-offset-[6px] pb-16 lg:pb-0">
              Read More
            </span>
          </a>
        </>
      ))}
    </div>
  )
}
